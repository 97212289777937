<!--
 * @Author: your name
 * @Date: 2021-12-17 10:42:54
 * @LastEditTime: 2021-12-17 11:32:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /pt-website/src/components/layout/Footer.vue
-->
<template>
  <div class="footer">
    <div class="wrap">
      <div class="column">
        <div class="big-tit" @click="changePage('/about')">关于我们</div>
        <div class="small-tit">公司介绍</div>
        <div class="small-tit">资质荣誉</div>
      </div>
      <div class="column">
        <div class="big-tit" @click="changePage('/product')">产品中心</div>
        <div class="small-tit">医疗版脉诊仪</div>
        <div class="small-tit">社区版脉诊仪</div>
        <div class="small-tit">四诊仪</div>
      </div>
      <div class="column">
        <div class="big-tit" @click="changePage('/news')">新闻资讯</div>
        <div class="small-tit">新闻资讯</div>
      </div>
      <div class="column">
        <div class="big-tit" @click="changePage('/contact')">联系我们</div>
        <div class="small-tit">联系方式</div>
        <div class="small-tit">留言反馈</div>
      </div>
      <div class="column qrcode">
        <img src="./../../assets/img/qrcode.jpg" alt="">
        <div class="text">扫码进入体质测试</div>
      </div>
    </div>
    <div class="copyright">
      <span>&copy;北京帕斯泰克科技有限公司 </span>
      <a href="https://beian.miit.gov.cn">京ICP备19002298号-2</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wFooter',
  data () {
    return {

    }
  },
  methods: {
    changePage (data) {
      this.$router.push(data)
    }
  }
}
</script>

<style lang="stylus" scoped>
.footer
  width 100%
  background #282828
  padding 0.6rem 0 0.35rem 3.22rem
  color #fff
  .wrap
    display flex
    .column
      margin-right 1rem
      margin-bottom 0.62rem
      .big-tit
        font-size 0.2rem
        margin-bottom 0.3rem
        cursor pointer
      .small-tit
        margin-bottom 0.2rem
        color #ccc
        font-size 0.14rem
    .qrcode
      width 1.8rem
      text-align center
      margin-left 2.5rem
      & img
        width 1.8rem
        margin-bottom 0.1rem
  .copyright
    font-size 0.1rem
</style>
