<template>
  <div>
    <!-- 导航栏 -->
    <div class="header">
      <div class="left">
        <img src="./../../assets/img/logo.png" alt="" class="logo">
      </div>
      <div class="right">
        <div v-for="(v, k) in title" :key="k" :class="[v == activeTit ? 'active' : '', 'nav']" @click="changePage(v)">{{v}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wHeader',
  props: ['activeTit'],
  data () {
    return {
      title: ['首页', '关于我们', '产品中心', '新闻资讯', '联系我们']
    }
  },
  methods: {
    changePage (v) {
      if (v === '首页') {
        this.$router.push('/homepage')
      } else if (v === '关于我们') {
        this.$router.push('/about')
      } else if (v === '产品中心') {
        this.$router.push('/product')
      } else if (v === '新闻资讯') {
        this.$router.push('/news')
      } else if (v === '联系我们') {
        this.$router.push('/contact')
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import './../../assets/css/basic.styl'
.header
  font-size $fontSmall
  width 100%
  height 1.03rem
  padding-right 2.58rem
  padding-left 1.71rem
  display flex
  align-items center
  justify-content space-between
  .left
    & .logo
      width 2.21rem
  .right
    width 8.22rem
    display flex
    justify-content space-between
    .nav
      @extend .bold
      cursor pointer
    .active
      color $pink
</style>
